<template>
  <div class="content">
	
    <div class="left-menu">
		<div class="left-menu-item" @click="active_item = 0">
			<i class="item-icon el-icon-c-scale-to-original"></i>
			页面设置
		</div>
		<div class="left-menu-item" @click="active_item = 1;setTitleText(1)">
			<i class="item-icon el-icon-edit-outline"></i>
			插入文字
		</div>
		<el-upload
		  action="''"
		  drag
		  :auto-upload="false"
		  :show-file-list="false"
		  :on-change="insertImage">
			<div class="left-menu-item" @click="active_item = 2">
				<i class="item-icon el-icon-picture-outline"></i>
				插入图片
			</div>
		</el-upload>
		<div class="left-menu-item" @click="active_item = 3;insertVarImg()">
			<i class="item-icon el-icon-picture-outline-round"></i>
			插入头像
		</div>
		<div class="left-menu-item" @click="active_item = 3;insertVartext('队员姓名',30)">
			<i class="item-icon el-icon-price-tag"></i>
			插入姓名
		</div>
		<div class="left-menu-item" @click="active_item = 3;insertVartext('身份证号',50)">
			<i class="item-icon el-icon-user"></i>
			插入身份证号
		</div>
		<div class="left-menu-item" @click="active_item = 3;insertVartext('队伍名称',30)">
			<i class="item-icon el-icon-trophy"></i>
			插入队伍名称
		</div>
		<div class="left-menu-item" @click="active_item = 3;insertVartext()">
			<i class="item-icon el-icon-paperclip"></i>
			可修改文字
		</div>
		<div class="left-menu-item" @click="active_item = 4;insertLine()">
			<i class="item-icon el-icon-download"></i>
			插入横线
		</div>
	</div>
    <div class="main-panel">
		<div class="toolbar" v-if="editCert">
			
			<!-- 页面设置 -->
			<div class="toolbar-item" v-show="active_item == 0" @click="changePageSize">
				<i class="iconfont icon-a-zhizhangfangxiang1x"></i>
				<span class="toolbar-tips">纸张方向</span>
			</div>
			
			<div class="toolbar-item" v-show="active_item == 0">
				<el-upload
				  action="''"
				  drag
				  :auto-upload="false"
				  :show-file-list="false"
				  :on-change="handleChangeUpload">
					<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
						<i class="iconfont icon-diwenwenben"></i>
						<span class="toolbar-tips">设置背景</span>
					</div>
				</el-upload>
			</div>
			
			<!-- 插入文本 -->
			<!-- <div class="toolbar-item" v-show="active_item == 1" @click="setTitleText(1)">
				<i class="iconfont icon-charuwenben"></i>
				<span class="toolbar-tips">插入文本</span>
			</div> -->
			<div class="toolbar-item" v-show="active_item == 1">
				<compact-picker v-if="showColorPicker" :value="color" @input="updateColor"></compact-picker>
				<i class="iconfont icon-wenziyanse" style="cursor: no-drop;color: #aaa;" v-if="draggingIndex == null || draggingMode == 'img' || draggingMode == 'line'"></i>
				<i class="iconfont icon-wenziyanse" @click="showColorPicker = true" :style="{color: draggingIndex == null || draggingMode == 'img' || draggingMode == 'line' ? '#000':titleText[draggingIndex].color}" v-else></i>
				<!-- <span class="toolbar-tips">文字颜色</span> -->
			</div>
			<div class="toolbar-item" v-show="active_item == 1">
				<el-dropdown trigger="click" style="cursor: pointer;" :disabled="draggingIndex == null || draggingMode == 'img' || draggingMode == 'line'">
					<span class="el-dropdown-link">
						{{draggingIndex == null || draggingMode == 'img' || draggingMode == 'line' ? '文字字号' : titleText[draggingIndex].font_size+'px'}} <i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item v-for="(item,index) in fontSize_List" :key="index" @click.native="titleText[draggingIndex].font_size = item">{{item}}px</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="toolbar-item" v-show="active_item == 1">
				<el-dropdown trigger="click" style="cursor: pointer;" :disabled="draggingIndex == null || draggingMode == 'img' || draggingMode == 'line'">
					<span class="el-dropdown-link">
						{{draggingIndex == null || draggingMode == 'img' || draggingMode == 'line' ? '字体' : titleText[draggingIndex].font_family}} <i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown" v-if="draggingIndex != null && draggingMode != 'img' || draggingMode == 'line'">
						<el-dropdown-item v-for="(item,index) in fontFamily_List" :key="index" @click.native="titleText[draggingIndex].font_family = item">{{item}}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<!-- <div class="toolbar-item" v-show="active_item == 1" v-if="editText" @click="underlineSelection()">
				<i class="iconfont icon-zitixiahuaxian" style="font-size: 17px;"></i>
				<span class="toolbar-tips">下划线</span>
			</div> -->
			<div class="toolbar-item" v-show="active_item == 1" v-if="draggingIndex != null && draggingMode != 'img' || draggingMode == 'line'" @click="removeDiv()">
				<i class="el-icon-delete" style="font-size: 17px;"></i>
				<span class="toolbar-tips">删除</span>
			</div>
			
			<!-- 插入图片 -->
	
			
			<!-- <div class="toolbar-item" v-show="active_item == 2">
				<el-upload
				  action="''"
				  drag
				  :auto-upload="false"
				  :show-file-list="false"
				  :on-change="insertImage">
					<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
						<i class="iconfont icon-chatu"></i>
						<span class="toolbar-tips">插入图片</span>
					</div>
				</el-upload>
			</div> -->
			<div class="toolbar-item" v-show="active_item == 2" v-if="draggingIndex != null && draggingMode != 'text' && draggingMode != 'line'">
				<el-input v-model="imgList[draggingIndex].width" placeholder="像素" size="mini" style="width: 55px;"></el-input>
				<span class="toolbar-tips">图片宽度</span>
			</div>
			<div class="toolbar-item" v-show="active_item == 2" v-if="draggingIndex != null && draggingMode != 'text' && draggingMode != 'line'">
				<el-input v-model="imgList[draggingIndex].height" placeholder="像素" size="mini" style="width: 55px;"></el-input>
				<span class="toolbar-tips">图片高度</span>
			</div>
			<div class="toolbar-item" v-show="active_item == 2" v-if="draggingIndex != null && draggingMode != 'text' && draggingMode != 'line'" @click="imgRotate('l')">
				<i class="el-icon-refresh-left" style="font-size: 17px;"></i>
				<span class="toolbar-tips">向左旋转</span>
			</div>
			<div class="toolbar-item" v-show="active_item == 2" v-if="draggingIndex != null && draggingMode != 'text' && draggingMode != 'line'" @click="imgRotate('r')">
				<i class="el-icon-refresh-right" style="font-size: 17px;"></i>
				<span class="toolbar-tips">向右旋转</span>
			</div>
			<div class="toolbar-item" v-show="active_item == 2" v-if="draggingIndex != null && draggingMode != 'text' && draggingMode != 'line'" @click="removeDiv()">
				<i class="el-icon-delete" style="font-size: 17px;"></i>
				<span class="toolbar-tips">删除</span>
			</div>
			
			<!-- 插入变量 -->
			<!-- <div class="toolbar-item" v-show="active_item == 3" @click="insertVartext()">
				<i class="iconfont icon-charuwenben"></i>
				<span class="toolbar-tips">文字变量</span>
			</div>
			<div class="toolbar-item" v-show="active_item == 3" @click="insertVarImg()">
				<i class="iconfont icon-chatu"></i>
				<span class="toolbar-tips">图片变量</span>
			</div>
			<div class="toolbar-item" style="color: #ccc" v-if="active_item == 3 && varIndex != null">
				|
			</div> -->
			<div class="toolbar-item" v-show="active_item == 3 && varIndex != null && varMode == 'text'">
				<compact-picker v-if="showColorPicker" :value="color" @input="updateColorVar"></compact-picker>
				<i class="iconfont icon-wenziyanse" style="cursor: no-drop;color: #aaa;" v-if="varIndex == null || varMode == 'img'"></i>
				<i class="iconfont icon-wenziyanse" @click="showColorPicker = true" :style="{color: varIndex == null || varMode == 'img' ? '#000':textVarList[varIndex].color}" v-else></i>
				<!-- <span class="toolbar-tips">文字颜色</span> -->
			</div>
			<div class="toolbar-item" v-show="active_item == 3 && varIndex != null && varMode == 'text'">
				<el-dropdown trigger="click" style="cursor: pointer;" :disabled="varIndex == null || varMode == 'img'">
					<span class="el-dropdown-link">
						{{varIndex == null || varMode == 'img' ? '文字字号' : textVarList[varIndex].font_size+'px'}} <i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item v-for="(item,index) in fontSize_List" :key="index" @click.native="textVarList[varIndex].font_size = item">{{item}}px</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="toolbar-item" v-show="active_item == 3 && varIndex != null && varMode == 'text'">
				<el-dropdown trigger="click" style="cursor: pointer;" :disabled="varIndex == null || varMode == 'img'">
					<span class="el-dropdown-link">
						{{varIndex == null || varMode == 'img' ? '字体' : textVarList[varIndex].font_family}} <i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown" v-if="varIndex != null && varMode != 'img'">
						<el-dropdown-item v-for="(item,index) in fontFamily_List" :key="index" @click.native="textVarList[varIndex].font_family = item">{{item}}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			
			
			<div class="toolbar-item" v-show="active_item == 3 && varIndex != null && varMode == 'text'" @click="textVarList[varIndex].text_align = 'left'">
				<i class="iconfont icon-T-juzuo" style="font-size: 17px;"></i>
				<span class="toolbar-tips">居左</span>
			</div>
			
			<div class="toolbar-item" v-show="active_item == 3 && varIndex != null && varMode == 'text'" @click="textVarList[varIndex].text_align = 'center'">
				<i class="iconfont icon-T-juzhong" style="font-size: 17px;"></i>
				<span class="toolbar-tips">居中</span>
			</div>
			
			<div class="toolbar-item" v-show="active_item == 3 && varIndex != null && varMode == 'text'" @click="textVarList[varIndex].text_align = 'right'">
				<i class="iconfont icon-T-juyou" style="font-size: 17px;"></i>
				<span class="toolbar-tips">居右</span>
			</div>
			
			<div class="toolbar-item" v-show="active_item == 3" v-if="varIndex != null && varMode == 'text'">
				<el-input v-model="textVarList[varIndex].width" placeholder="像素" size="mini" style="width: 56px;"></el-input>
				<span class="toolbar-tips">宽度(%)</span>
			</div>
			
			<div class="toolbar-item" v-show="active_item == 3" v-if="varIndex != null && varMode != 'text'">
				<el-input v-model="imgVarList[varIndex].width" placeholder="像素" size="mini" style="width: 56px;"></el-input>
				<span class="toolbar-tips">图片宽度</span>
			</div>
			<div class="toolbar-item" v-show="active_item == 3" v-if="varIndex != null && varMode != 'text'">
				<el-input v-model="imgVarList[varIndex].height" placeholder="像素" size="mini" style="width: 56px;"></el-input>
				<span class="toolbar-tips">图片高度</span>
			</div>
			
			<div class="toolbar-item" v-if="varIndex != null" @click="removeVar()">
				<i class="el-icon-delete" style="font-size: 17px;"></i>
				<span class="toolbar-tips">删除</span>
			</div>
			
			<div class="toolbar-item" v-show="active_item == 4" v-if="draggingIndex != null && draggingMode != 'text' && draggingMode != 'img'">
				<el-input v-model="lineList[draggingIndex].width" placeholder="像素" size="mini" style="width: 56px;"></el-input>
				<span class="toolbar-tips">横线长度</span>
			</div>
			<div class="toolbar-item" v-show="active_item == 4" v-if="draggingIndex != null && draggingMode != 'text' && draggingMode != 'img'">
				<el-input v-model="lineList[draggingIndex].weight" placeholder="像素" size="mini" style="width: 56px;"></el-input>
				<span class="toolbar-tips">横线粗细</span>
			</div>
			<div class="toolbar-item" v-show="active_item == 4" v-if="draggingIndex != null && draggingMode != 'text' && draggingMode != 'img'">
				<compact-picker v-if="showColorPicker" :value="linecolor" @input="updateColor"></compact-picker>
				<i class="iconfont icon-yanse1" style="cursor: no-drop;color: #aaa;" v-if="draggingIndex == null || draggingMode == 'img' || draggingMode == 'text'"></i>
				<i class="iconfont icon-yanse1" @click="showColorPicker = true" :style="{color: draggingIndex == null || draggingMode == 'img' || draggingMode == 'text' ? '#000':lineList[draggingIndex].color}" v-else></i>
			</div>
			
			<div class="toolbar-item" v-if="active_item == 4" @click="removeLine()">
				<i class="el-icon-delete" style="font-size: 17px;"></i>
				<span class="toolbar-tips">删除</span>
			</div>
			
			<div class="toolbar-item" style="position: absolute;right: 20px;top: 20px;" v-if="editCert && !isEdit" @click="saveCert">
				<i class="el-icon-document-checked" style="font-size: 17px;"></i>
				<span class="toolbar-tips">保存</span>
			</div>
			
			<div class="toolbar-item" style="position: absolute;right: 20px;top: 20px;" v-else @click="updateCert">
				<i class="el-icon-document-checked" style="font-size: 17px;"></i>
				<span class="toolbar-tips">保存</span>
			</div>
			
			<div class="toolbar-item" style="position: absolute;right: 60px;top: 20px;" v-if="editCert && isEdit" @click="saveCert">
				<i class="el-icon-document-add" style="font-size: 17px;"></i>
				<span class="toolbar-tips">另存一份</span>
			</div>
				
			
		</div>
		<div class="newCert" v-if="!editCert" @click="createCert">
			<el-button type="primary" round icon="el-icon-circle-plus-outline" style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)">新建模板</el-button>
		</div>
		<div 
		@click.self="hideBorder"
		@mouseenter="setHovering(true)"
		@mouseleave="setHovering(false)"
		class="cert-box" 
		v-show="editCert"
		ref="contentToCapture"
		:style="{width: pageWidth+'px',height:pageHeight+'px',background: 'url('+cert_background+')',marginTop: this.page_size == '2' ? '60px' : '0px'}"
		>
			<div 
				v-show="editText != true || draggingIndex != index"
				@mousedown="startDrag(index,$event,'text')"
				@dblclick.stop="dbeditText(index,'text')"
				@click.stop="showborder(index,'text')"
				class="title-div top-level" 
				v-for="(item,index) in titleText" 
				:key="'text'+index" 
				ref="titleItems"
				:style="{fontSize: item.font_size+'px',color:item.color,top: item.positon_y+'px',left: item.positon_x+'px',fontWeight: item.font_weight,fontFamily: item.font_family,border: item.border}"
				v-html="item.value"
			>
			</div>
			
			<div
				@mousedown="startDrag(index,$event,'line')"
				@click.stop="showborder(index,'line')"
				class="title-div top-level" 
				ref="lineItems"
				v-for="(item,index) in lineList" 
				:key="'line'+index" 
				:style="{width: item.width+'px',border: item.border,position: 'relative',height: '20px',top: item.positon_y+'px',left: item.positon_x+'px'}"
				
			>
				<div :style="{width: item.width+'px',borderBottom: item.weight+'px solid '+item.color,height: '10px'}">
					
				</div>
			</div>
			
			<div @mousedown="startDrag(index,$event,'img')"
				@click.stop="showborder(index,'img')"
				class="title-div bottom-level" 
				ref="imgItems"
				v-for="(item,index) in imgList" 
				:key="'img'+index" 
				:style="{width: item.width+'px',height: item.height == 'auto' ? item.height : item.height+'px',opacity: item.opacity, top: item.positon_y+'px',left: item.positon_x+'px',border: item.border,transform: 'rotate('+item.transform+'deg)'}">
				<img :src="item.img" alt="" class="img-move" />
			</div>
			
			<div
				@mousedown="startDragVar(index,$event,'text')"
				@click.stop="showborderVar(index,'text')"
				class="title-div top-level" 
				v-for="(item,index) in textVarList" 
				:key="'vartext'+index" 
				ref="titleVarItems"
				:style="{width: item.width+'%',fontSize: item.font_size+'px',color:item.color,top: item.positon_y+'px',left: item.positon_x+'px',fontWeight: item.font_weight,fontFamily: item.font_family,border: item.border,textAlign: item.text_align}"
			>
				{{"$"+item.name+"$"}}
			</div>
			
			
			
			<input
			:style="{
				fontSize: titleText[draggingIndex].font_size+'px',
				color:titleText[draggingIndex].color,
				top: titleText[draggingIndex].positon_y+'px',
				left: titleText[draggingIndex].positon_x+'px',
				fontWeight: titleText[draggingIndex].font_weight,
				fontFamily: titleText[draggingIndex].font_family,
				border: titleText[draggingIndex].border,
				width: inputWidth+'px',
				
			}"
			ref="input"
			type="text" 
			v-model="titleText[draggingIndex].value" class="editInput"
			v-if="editText == true"
			 @mouseup="handleSelection"
			
			/>
			
			
			
			<div @mousedown="startDragVar(index,$event,'img')"
				@click.stop="showborderVar(index,'img')"
				class="title-div bottom-level var-img" 
				v-for="(item,index) in imgVarList" 
				:key="'varimg'+index" 
				ref="imgVarItems"
				:style="{width: item.width+'px',height: item.height == 'auto' ? item.height : item.height+'px', top: item.positon_y+'px',left: item.positon_x+'px',border: item.border}">
				{{"$"+item.name+"$"}}
			</div>
		</div>
	</div>
    <div class="right-controll">
		<el-skeleton :loading="loading" animated :count="loadingNum">
			<template slot="template">
				<div style="display: flex;margin-bottom: 40px;">
				<el-skeleton-item
					variant="image"
					style="width: 100px;height: 100px;"
				/>
				<div style="padding: 14px;">
					<el-skeleton-item variant="h3" style="width: 200px;" />
					<div
						style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
					>
						<el-skeleton-item variant="text" style="margin-right: 16px;" />
						<el-skeleton-item variant="text" style="width: 30%;" />
					</div>
				</div>
				</div>
			</template>
			<div v-for="(item,index) in certList" :key="'model'+index" class="certModel">
				<div class="cert-mask-box">
					<el-image
						  style="width: 100px; height: 100px;border: 1px solid #ccc;"
						  :src="item.preImg"
						  fit="contain"></el-image>
						<div class="cert-mask" v-if="isEdit && EditIndex == index">编辑中</div>
				</div>
				<div class="cert-info">
					<span class="cert-name">{{item.name}}</span>
					<span class="cert-update-time">编辑时间：{{item.update_time}}</span>
				</div>
				<div class="cert-btns">
					<el-button class="cert-btns-btn" type="primary" size="mini" @click="editCertModel(index)">编辑</el-button>
					<el-button class="cert-btns-btn" type="danger" size="mini" @click="deleteCert(item.id)">删除</el-button>
				</div>
				
			</div>
		</el-skeleton>
	</div>
	<my-cropper :showDialog="dialogVisible" :image="cropperImage" @update-logo="handleLogoUpdate" @cancelDialog="closeDialog" :width="pageWidth * (500/pageHeight)" :height="pageHeight * (500/pageHeight)"></my-cropper>
  </div>
</template>

<script>
import { Compact } from 'vue-color'
import html2canvas from 'html2canvas';
export default {
	components: {
	    'compact-picker': Compact
	},
	data() {
		return {
			selectedText: '',
			color: "#000",
			active_item: 0,
			dialogVisible: false,
			cropperImage: "",
			showColorPicker: false,
			size_option: [
				{
					label: "竖版",
					value: "1"
				},
				{
					label: "横版",
					value: "2"
				}
			],
			familyList: [
				{
					value: "黑体",
				},
				{
					value: "宋体",
				},
				{
					value: "楷体",
				},
				{
					value: "微软雅黑",
				},
				{
					value: "仿宋",
				}
			],
			page_size: "1",
			cert_background: "",
			pageWidth: 496,
			pageHeight: 700,
			isHovering: false,
			aspectRatio: 210 / 297,
			titleText: [],
			imgList: [],
			lineList: [],
			draggingIndex: null,
			varIndex: null,
			draggingMode: null,
			varMode: null,
			offsetX: 0,
			offsetY: 0,
			fontSize_List: [16,17,18,19,20,22,24,28,32,36,42,48,60],
			fontFamily_List: ['宋体','仿宋','楷体','黑体','微软雅黑'],
			editText: false,
			inputWidth: null,
			textVarList: [],
			imgVarList: [],
			editCert: false,
			certList: [],
			certName: "",
			isEdit: false,
			EditIndex: '',
			linecolor: "#000",
			editId: 0,
			loading: true,
			loadingNum: 0,
			certCenterX: 0,
			certCenterY: 0,
			ads: false
		}
	},
	methods: {
		handleSelection() {
		    this.selectedText = window.getSelection().toString();
			
		},
		underlineSelection() {
		    if (this.selectedText) {
				let titleValue = this.titleText[this.draggingIndex].value
		        this.titleText[this.draggingIndex].value = titleValue.replace(this.selectedText, '<text style="text-decoration: underline;">'+this.selectedText+'</text>');
				this.editText = false
		    }
		},
		fetchData() {
			this.loading = true
			this.$api.get('api/certbook/List')
			.then(response => {
				this.certList = []
				let count = 0
				const result = response.data.rows
				this.loadingNum = result.length
				result.forEach(item=>{
					this.$api.get(`api/certbook/Get/${item.id}`)
					.then(response => {
					    if(response.data.code == 1) {
							let obj = JSON.parse(response.data.data.content)
							obj.id = item.id
							this.certList.push(obj)
							
					    } else {
					        this.$message({
					            type: 'error',
					            message: response.data.data
					        });
					    }
						count++
						if (count == result.length) {
							this.loading = false
						}
					  
					})
					.catch(error => {
					  console.error("There was an error!", error);
					});
				})
			})
			.catch(error => {
			  console.error("There was an error!", error);
			});
		},
		handleLogoUpdate(croppedImageUrl) {
			let blobImage = this.base64ToBlob(croppedImageUrl);
			const loading = this.$loading({
			  lock: true,
			  text: '上传中，请稍后...',
			  spinner: 'el-icon-loading',
			  background: 'rgba(255, 255, 255, 0.7)'
			});
			let formData = new FormData();
			formData.append('file', blobImage, +new Date+'.jpg');
			this.$api.post('api/certbook/img',formData).then(response => {
				this.cert_background = response.data.data
				loading.close()
			}).catch(error => {
				console.error("There was an error!", error);
			})	
		},
		base64ToBlob(base64Data) {
		    // 分割数据
		    let parts = base64Data.split(';base64,');
		    let contentType = parts[0].split(':')[1];
		    let raw = window.atob(parts[1]);
		    let rawLength = raw.length;
		    let uInt8Array = new Uint8Array(rawLength);
		
		    for (let i = 0; i < rawLength; ++i) {
		        uInt8Array[i] = raw.charCodeAt(i);
		    }
		    return new Blob([uInt8Array], {type: contentType});
		},
		closeDialog() {
		    this.dialogVisible = false
		},
		handleChangeUpload(file) {
		    const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
		    const isLt2M = file.size / 1024 / 1024 < 20;
		    if (!isJPG) {
		        this.$message.error('上传图片只能是 JPG/PNG 格式!');
		        return false
		    }
		    if (!isLt2M) {
		        this.$message.error('上传图片大小不能超过 2MB!');
		        return false
		    }
		    // 上传成功后将图片地址赋值给裁剪框显示图片
		    this.$nextTick(async () => {
		        // base64方式
		        // this.option.img = await fileByBase64(file.raw)
		        console.log("成功", URL.createObjectURL(file.raw))
		        this.cropperImage = URL.createObjectURL(file.raw)
		        this.dialogVisible = true
		    })
		},
		insertImage(file) {
			const loading = this.$loading({
			  lock: true,
			  text: '上传中，请稍后...',
			  spinner: 'el-icon-loading',
			  background: 'rgba(255, 255, 255, 0.7)'
			});
			const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
			const isLt2M = file.size / 1024 / 1024 < 20;
			if (!isJPG) {
			    this.$message.error('上传图片只能是 JPG/PNG 格式!');
			    return false
			}
			if (!isLt2M) {
			    this.$message.error('上传图片大小不能超过 2MB!');
			    return false
			}
			// 上传成功后将图片地址赋值给裁剪框显示图片
			let formData = new FormData();
			formData.append('file', file.raw, +new Date+'.jpg');
			this.$api.post('api/certbook/img',formData).then(response => {
				let imgData = {
					img: response.data.data,
					width: 300,
					height: 'auto',
					opacity: 1,
					positon_x: 120,
					positon_y: 240,
					border: "1px solid rgba(0,0,0,0)",
					transform: 0
				}
				this.imgList.push(imgData)
				loading.close()
			}).catch(error => {
				console.error("There was an error!", error);
			})	
		},
		setSzie() {
			
			switch(this.page_size) {
				case "1": 
					this.pageWidth = 496;
					this.pageHeight = 700;
					this.aspectRatio = 210 / 297
					break;
				case "2":
					this.pageWidth = 700;
					this.pageHeight = 496;
					this.aspectRatio = 297 / 210
					break;
					
			}
			console.log(this.pageWidth)
		},
		getBase64 (file) {
		    return new Promise((resolve, reject) => {
		        let reader = new FileReader();
		        let imgResult = "";
		        reader.readAsDataURL(file);
		        reader.onload = function () {
		          imgResult = reader.result;
		        };
		        reader.onerror = function (error) {
		          reject(error);
		        };
		        reader.onloadend = function () {
		          resolve(imgResult);
		        };
		    });
		},
		onWheel(event) {
		    if (this.isHovering && this.draggingIndex != null) {
				event.preventDefault();
				let item = null
				if  (this.draggingMode == "text") {
					item = this.titleText[this.draggingIndex];
				} else {
					item = this.imgList[this.draggingIndex];
				}
				
				let delta = item.width/item.height
				
				event.deltaY > 0 ? item.width = Number(item.width) - 10 : item.width = Number(item.width) + 10;
				if (item.height != 'auto') {
					item.height = Number(item.width) / delta;
				}
				
		    }
			
			if (this.isHovering && this.varIndex != null) {
				event.preventDefault();
				let item = null
				if  (this.varMode == "text") {
					item = this.textVarList[this.varIndex];
				} else {
					item = this.imgVarList[this.varIndex];
				}
				
				let delta = item.width/item.height
				
				event.deltaY > 0 ? item.width = Number(item.width) - 10 : item.width = Number(item.width) + 10;
				if (item.height != 'auto') {
					item.height = Number(item.width) / delta;
				}
			}
		},
		setHovering(state) {
			this.isHovering = state
		},
		setTitleText(m) {
			let text = ""
			if (m == 1) {
				text = {
					value: "设置标题文字",
					font_family: "楷体",
					color: "#dc7601",
					font_weight: 600,
					font_size: 28,
					positon_x: 120,
					positon_y: 240,
					border: "1px solid rgba(0,0,0,0)"
				}
			} else {
				text = {
					value: "设置正文文字",
					font_family: "微软雅黑",
					color: "#000",
					font_weight: 400,
					font_size: 20,
					positon_x: 120,
					positon_y: 240,
					border: "1px solid rgba(0,0,0,0)"
				}
			}
			
			this.titleText.push(text)
		},
		insertVartext(val='',width=50) {
			
			
			if (val == '') {
				this.$prompt('请输入变量名称', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(({ value }) => {
					let text = {
						name: value,
						positon_x: 0,
						positon_y: 240,
						font_family: "楷体",
						color: "#02245b",
						font_weight: 600,
						font_size: 28,
						border: "1px solid rgba(0,0,0,0)",
						text_align: "left",
						width: width
					}
					this.textVarList.push(text)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消添加'
					});       
				});
			}else {
				let text = {
					name: val,
					positon_x: 0,
					positon_y: 240,
					font_family: "楷体",
					color: "#02245b",
					font_weight: 600,
					font_size: 28,
					border: "1px solid rgba(0,0,0,0)",
					text_align: "left",
					width: width
				}
				this.textVarList.push(text)
			}
			
			// this.$prompt('请输入变量名称', '提示', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// }).then(({ value }) => {
				
			// }).catch(() => {
			// 	this.$message({
			// 		type: 'info',
			// 		message: '取消添加'
			// 	});       
			// });
			
		},
		insertLine() {
			let line = {
				width: 200,
				weight: 2,
				positon_x: 120,
				positon_y: 240,
				color: '#000'
			}
			this.lineList.push(line)
			console.log('横线',this.lineList)
		},
		insertVarImg() {
			let text = {
				name: '头像',
				positon_x: 120,
				positon_y: 240,
				width: 100,
				height: 100,
				border: "1px dashed #ccc"
			}
			this.imgVarList.push(text)
			// this.$prompt('请输入变量名称', '提示', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// }).then(({ value }) => {
				
			// }).catch(() => {
			// 	this.$message({
			// 		type: 'info',
			// 		message: '取消添加'
			// 	});       
			// });
			
		},
		startDrag(index, e, mode) {
			this.draggingIndex = index;
			this.draggingMode = mode
			let item = ""
			if (mode == "text") {
				item = this.titleText[index];
				
			} else if(mode == "line") {
				item = this.lineList[index];
				
			} else {
				item = this.imgList[index];
				
			}
			
			this.offsetX = e.clientX - item.positon_x;
			this.offsetY = e.clientY - item.positon_y;
			setTimeout(() => {
			    this.ads = true;
			}, 3000);
			document.addEventListener('mousemove', this.onDrag);
			document.addEventListener('mouseup', this.stopDrag);
		},
		startDragVar(index, e, mode) {
			this.varIndex = index;
			this.varMode = mode
			let item = ""
			if (mode == "text") {
				item = this.textVarList[index];
			} else if(mode == "line") {
				item = this.lineList[index];
			} else {
				item = this.imgVarList[index];
			}
			
			this.offsetX = e.clientX - item.positon_x;
			
			this.offsetY = e.clientY - item.positon_y;
			setTimeout(() => {
			    this.ads = true;
			}, 3000);
			document.addEventListener('mousemove', this.onDragvar);
			document.addEventListener('mouseup', this.stopDrag);
		},
		onDrag(e) {
		  if (this.draggingIndex !== null) {
			  
				  let item = ""
				  let divElement = null
				  if (this.draggingMode == "text") {
					  item = this.titleText[this.draggingIndex];
					  divElement = this.$refs.titleItems[this.draggingIndex];
				  } else if(this.draggingMode == "line") {
					item = this.lineList[this.draggingIndex];
					divElement = this.$refs.lineItems[this.draggingIndex];
				  } else {
					  item = this.imgList[this.draggingIndex];
					  divElement = this.$refs.imgItems[this.draggingIndex];
					  
				  }

						// 获取div的宽高
					const rect = divElement.getBoundingClientRect();
					const width = rect.width;
					const height = rect.height;
					
					
					
					
					
					
					item.positon_x = e.clientX - this.offsetX;
					item.positon_y = e.clientY - this.offsetY;
					
					let dragXCenter = item.positon_x + width / 2
					let dragYCenter = item.positon_y + height / 2 
					console.log("状态",this.ads)
					if (this.ads == true && Math.abs(dragXCenter - this.certCenterX) == 10) {
						this.ads = false
					  	item.positon_x = this.certCenterX -  width / 2
						this.stopDrag()
					}
					
					if (this.ads == true && Math.abs(dragYCenter - this.certCenterY) == 10) {
						this.ads = false
					  	item.positon_y = this.certCenterY - height / 2 
						this.stopDrag()
					}
		    }
		},
		onDragvar(e) {
		  if (this.varIndex !== null) {
			  let item = ""
			  let divElement = null
			  if (this.varMode == "text") {
				  item = this.textVarList[this.varIndex];
				  divElement = this.$refs.titleVarItems[this.varIndex];
			  } else {
				  item = this.imgVarList[this.varIndex];
				  divElement = this.$refs.imgVarItems[this.varIndex];
			  }
			  
			  const rect = divElement.getBoundingClientRect();
			  const width = rect.width;
			  const height = rect.height;
			  
			  
		        
		        item.positon_x = e.clientX - this.offsetX;
				if (item.positon_x < 0) {
					item.positon_x = 0
				}
		        item.positon_y = e.clientY - this.offsetY;
				
				let dragXCenter = item.positon_x + Math.floor(width) / 2
				let dragYCenter = item.positon_y + height / 2 
				
				console.log(dragXCenter - this.certCenterX)
				if (this.ads == true && Math.floor(Math.abs(dragXCenter - this.certCenterX)) == 10 ) {
					this.ads = false
				  	item.positon_x = this.certCenterX -  width / 2
					this.stopDrag()
				}
				
				if (this.ads == true && Math.abs(dragYCenter - this.certCenterY) == 10) {
					this.ads = false
				  	item.positon_y = this.certCenterY - height / 2 
					this.stopDrag()
				}
				
		    }
		},
		stopDrag() {
		    document.removeEventListener('mousemove', this.onDrag);
		    document.removeEventListener('mousemove', this.onDragvar);
		    document.removeEventListener('mouseup', this.stopDrag);
			this.ads = false
		},
		showborderVar(index,mode) {
			this.hideBorder()
			this.varIndex = index;
			this.varMode = mode
			let item = null
			if (this.varMode == "text") {
				item = this.textVarList[this.varIndex];
				this.active_item = 3
			} else {
				item = this.imgVarList[this.varIndex];
				this.active_item = 3
			}
			item.border = "1px solid #e34b04";
			
			
		},
		showborder(index,mode) {
			this.hideBorder()
			this.draggingIndex = index;
			this.draggingMode = mode
			let item = null
			if (this.draggingMode == "text") {
				item = this.titleText[this.draggingIndex];
				this.active_item = 1
			} else if(this.draggingMode == "line") {
				item = this.lineList[this.draggingIndex];
				this.active_item = 4
			}else {
				item = this.imgList[this.draggingIndex];
				this.active_item = 2
			}
			item.border = "1px solid #048dcc";
		},
		dbeditText(index,mode) {
			this.$nextTick(() => {
			    this.inputWidth = this.$refs.titleItems[index].offsetWidth;
				this.hideBorder()
				this.draggingIndex = index;
				this.draggingMode = mode
				let item = this.titleText[this.draggingIndex];
				
				item.border = "3px solid #2c6300";
				this.editText = true
				setTimeout(() => {
					this.$refs.input.focus();
					this.$refs.input.select();
				}, 0);
			    
			});	
		},
		hideBorder() {
			this.draggingIndex = null;
			this.varIndex = null;
			this.draggingMode = null;
			this.varMode = null;
			this.titleText.forEach((item) => {
				item.border = "1px solid rgba(0,0,0,0)"
			})
			this.imgList.forEach((item) => {
				item.border = "1px solid rgba(0,0,0,0)"
			})
			this.textVarList.forEach((item) => {
				item.border = "1px solid rgba(0,0,0,0)"
			})
			
			this.lineList.forEach((item) => {
				item.border = "1px solid rgba(0,0,0,0)"
			})
			this.imgVarList.forEach((item) => {
				item.border = "1px dashed #ccc"
			})
			this.editText = false
		},
		updateColor(newColor) {
			if (this.draggingMode == 'line') {
				this.linecolor = newColor.hex
				this.lineList[this.draggingIndex].color = this.linecolor
			} else {
				this.color = newColor.hex
				this.titleText[this.draggingIndex].color = this.color
			}
			this.showColorPicker = false
			
		},
		updateColorVar(newColor) {
			this.color = newColor.hex
			this.textVarList[this.varIndex].color = this.color
			this.showColorPicker = false
		},
		changePageSize() {
			console.log(this.page_size)
			this.page_size == "1" ? this.page_size = "2" : this.page_size = "1"
			this.setSzie()
		},
		imgRotate(f) {
			let item = this.imgList[this.draggingIndex]
			if (f == "l") {
				item.transform = item.transform - 90
			} else {
				item.transform = item.transform + 90
			}
			
		},
		removeDiv() {
			let item = null
			if (this.draggingMode == "text") {
				item = this.titleText;
				this.active_item = 1
			} else {
				item = this.imgList;
				this.active_item = 2
			}
			item.splice(this.draggingIndex, 1);
			this.draggingIndex = null
		},
		removeVar() {
			let item = null
			if (this.varMode == "text") {
				item = this.textVarList;
				this.active_item = 3
			} else {
				item = this.imgVarList;
				this.active_item = 3
			}
			item.splice(this.varIndex, 1);
			this.varIndex = null
		},
		removeLine() {
			let item = null
			item = this.lineList;
			this.active_item = 4
			item.splice(this.draggingIndex, 1);
			this.draggingIndex = null
		},
		deleteCert(id) {
			this.$confirm('确认删除该模板？是否继续？', '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
				this.$api.get(`api/certbook/Del/${id}`)
				.then(response => {
				    if(response.data.code == 1) {
						this.certList = this.certList.filter(item=>item.id !== id)
				        this.$message({
				            type: 'success',
				            message: '删除成功!'
				        });
						this.fetchData()
				    } else {
				        this.$message({
				            type: 'error',
				            message: response.data.data
				        });
				    }
				  
				})
				.catch(error => {
				  console.error("There was an error!", error);
				});
			}).catch(() => {
			  this.$message({
			    type: 'info',
			    message: '已取消'
			  });          
			});
		},
		saveCert() {
			this.hideBorder()
			this.$confirm('确认保存该模板？是否继续？', '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true,
					text: '保存中',
					background: 'rgba(255, 255, 255, 0.6)'
				});
				const element = this.$refs.contentToCapture;
					html2canvas(element).then(canvas => {
					let imageUrl = canvas.toDataURL('image/png');
					let blobImage = this.base64ToBlob(imageUrl);
					let formData = new FormData();
					formData.append('file', blobImage, +new Date+'.jpg');
					this.$api.post('api/certbook/img',formData).then(response => {
						let certData = {
							name: this.certName,
							preImg: response.data.data,
							width: this.pageWidth,
							height: this.pageHeight,
							background: this.cert_background,
							text: JSON.parse(JSON.stringify(this.titleText)),
							img: JSON.parse(JSON.stringify(this.imgList)),
							varText: JSON.parse(JSON.stringify(this.textVarList)),
							varImg: JSON.parse(JSON.stringify(this.imgVarList)),
							line: JSON.parse(JSON.stringify(this.lineList)),
							update_time: new Date().toLocaleString()
						}
						
						const payload = {
							id: 0,
						    title: this.certName,
						    content: JSON.stringify(certData),
						};
						
						this.$api.post('api/certbook/Save',payload,{
							headers: {
								'Content-Type': 'application/json;charset=UTF-8'
							}
						}).then(response => {
							loading.close();
							if (response.data.code == 1) {
								this.$message({
								    type: 'success',
								    message: '保存成功!'
								});
								this.isEdit = true
							}
							
							
							this.fetchData()
						}).catch(error => {
							console.error("There was an error!", error);
						})
						
					}).catch(error => {
						console.error("There was an error!", error);
					})	
					
					
					// this.certList.push(certData)
					// console.log('储存',JSON.stringify(this.certList))
				});
				
			}).catch(() => {
			  this.$message({
			    type: 'info',
			    message: '已取消'
			  });          
			});
			
			
		},
		updateCert() {
			this.hideBorder()
			this.$confirm('确认更新该模板？是否继续？', '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true,
					text: '保存中',
					// spinner: 'el-icon-loading',
					background: 'rgba(255, 255, 255, 0.6)'
				});
				const element = this.$refs.contentToCapture;
					html2canvas(element).then(canvas => {
					let imageUrl = canvas.toDataURL('image/png');
					let blobImage = this.base64ToBlob(imageUrl);
					let formData = new FormData();
					formData.append('file', blobImage, +new Date+'.jpg');
					this.$api.post('api/certbook/img',formData).then(response => {
						let certData = {
							name: this.certName,
							preImg: response.data.data,
							width: this.pageWidth,
							height: this.pageHeight,
							background: this.cert_background,
							text: JSON.parse(JSON.stringify(this.titleText)),
							img: JSON.parse(JSON.stringify(this.imgList)),
							varText: JSON.parse(JSON.stringify(this.textVarList)),
							varImg: JSON.parse(JSON.stringify(this.imgVarList)),
							line: JSON.parse(JSON.stringify(this.lineList)),
							update_time: new Date().toLocaleString()
						}
						
						console.log(response)
						if (this.editId == 0) {
							this.editId = this.certList[0].id
						}
						const payload = {
							id: this.editId,
						    title: this.certName,
						    content: JSON.stringify(certData),
						};
						
						this.$api.post('api/certbook/Save',payload,{
							headers: {
								'Content-Type': 'application/json;charset=UTF-8'
							}
						}).then(response => {
							loading.close();
							if (response.data.code == 1) {
								this.$message({
								    type: 'success',
								    message: '保存成功!'
								});
							}
							this.fetchData()
						}).catch(error => {
							console.error("There was an error!", error);
						})
					}).catch(error => {
						console.error("There was an error!", error);
					})
				});
				
			}).catch(() => {
			  this.$message({
			    type: 'info',
			    message: '已取消'
			  });          
			});
		},
		createCert() {
			this.$prompt('请输入模板名称', '提示', {
				confirmButtonText: '创建',
				cancelButtonText: '取消',
				inputPattern: /^\S+$/,
				inputErrorMessage: '模板名称不能为空'
			}).then(({ value }) => {
				this.certName = value
				this.editCert = true
				let that = this
				setTimeout(function() {
					that.setCenter()
				}, 500)
				
			}).catch((e) => {
				console.log(e)
				this.$message({
					type: 'info',
					message: '取消创建'
				});       
			});
		},
		setCenter() {
			const parentRect = this.$refs.contentToCapture.getBoundingClientRect();
			this.certCenterX = parentRect.width / 2,
			this.certCenterY = parentRect.height / 2
			console.log(this.certCenterX,this.certCenterY)
		},
		editCertModel(index) {
			this.$confirm('确认编辑该模板？', '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
				let item = this.certList[index]
				this.editId = item.id
				this.certName = item.name
				this.pageWidth = item.width
				this.pageHeight = item.height
				this.cert_background = item.background
				this.titleText = JSON.parse(JSON.stringify(item.text))
				this.imgList = JSON.parse(JSON.stringify(item.img))
				this.imgVarList = JSON.parse(JSON.stringify(item.varImg))
				this.textVarList = JSON.parse(JSON.stringify(item.varText))
				this.lineList = JSON.parse(JSON.stringify(item.line))
				this.editCert = true
				this.isEdit = true
				this.EditIndex = index
				if (item.width > item.height) {
					this.page_size = 2
				}
			}).catch(() => {
			  this.$message({
			    type: 'info',
			    message: '已取消'
			  });          
			});
		}
		
	},
	mounted() {
	    window.addEventListener('wheel', this.onWheel);
		
	},
	beforeDestroy() {
		window.removeEventListener('wheel', this.onWheel);
	},
	created() {
		this.fetchData()
		
		
	}
	

};
</script>
<style scoped>
	body {
		overflow-y: hidden;
	}
	.content {
		display: flex;
		width: 96%;
		height: 650px;
	}
	
	.left-menu {
		width: 8%;
		height: 100%;
	}
	
	.main-panel {
		background-color: #f7f7f7;
		height: 100%;
		width: 70%;
		display: flex;
		align-items: center;
		justify-content: start;
		flex-direction: column;
		border: 1px solid #ccc;
		overflow: hidden;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
		
	}
	
	.right-controll {
		width: 30%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: left;
		align-items: flex-start;
		/* background-color: #000; */
		padding-left: 20px;
		overflow-y: scroll;
		align-content: start;
	}
	
	.left-menu-item {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 90px;
		/* background-color: #010436; */
		align-items: center;
		justify-content: center;
		color: #000;
		cursor: pointer;
		font-size: 12px;
		line-height: 40px;
		border-left: 5px solid #fff;
	}
	
	.left-menu-item:hover {
		color: #f00;
	}
	
	.item-icon {
		font-size: 24px;
	}
	
	.active-item {
		border-left: 5px solid #f00;
		color: #f00;
	}
	
	.cert-box {
		border: 1px solid #ccc;
		background-size: 100% 100%!important;
		transition: width 0.3s, height 0.3s;
		background-color: #fff!important;
		position: relative;
		overflow: hidden;
	}
	
	.setting-item {
		display: flex;
		justify-content: left;
	}
	
	.setting-item-label {
		width: 150px;
		text-align: left;
		margin-left: 10px;
		margin-bottom: 40px;
	}
	
	.text-item {
		width: 100%;
		height: 60px;
		text-align: center;
		line-height: 60px;
	}
	
	.text-item:hover {
		color: #ff7700;
		cursor: pointer;
	}
	
	.add-title {
		font-size: 24px;
		font-weight: bold;
	}
	
	.add-text {
		font-size: 20px;
		
	}
	
	.title-div {
		position: absolute;
		cursor: move;
		white-space: pre;
		user-select: none;
		border: 1px solid rgba(255, 255, 255, 0);
	}
	
	.page-setting-text {
		display: flex;
		flex-wrap: nowrap;
		flex-grow: 1;
		flex-shrink: 0;
		
	}
	
	.img-move {
		pointer-events: none;
		width: 100%;
		height: 100%;
	}
	
	.toolbar {
		display: flex;
		background-color: #fff;
		width: 100%;
		height: 80px;
		/* line-height: 80px; */
		justify-content: center;
		position: relative;
	}
	
	.toolbar-item {
		margin: 0px 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	
	.toolbar i {
		font-size: 15px;
		text-align: center;
		cursor: pointer;
	}
	
	.left-menu /deep/ .el-upload-dragger {
		width: auto!important;
		border: 0px;
		height: 70px!important;
		overflow: unset;
		margin-bottom: 10px;
	}
	
	.toolbar-item /deep/ .el-upload-dragger {
		width: auto!important;
		border: 0px;
		height: 30px!important;
		overflow: unset;
		margin-bottom: 10px;
	}
	
	.toolbar-item /deep/ .el-input--mini {
		height: 16px;
	}
	
	.toolbar-tips {
		margin-top: 10px;
		color: #3a3a3a;
		font-size: 12px;
	}
	
	.vc-compact {
		position: absolute;
		z-index: 9;
	}
	
	.top-level {
		z-index: 90;
	}
	
	.bottom-level {
		z-index: 89;
	}
	
	.editInput {
		position: absolute;
		width: auto;
		outline: none;
		border: 0px;
	}
	
	.var-img {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.newCert {
		margin-top: 26%;
		
	}
	
	.certModel {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100px;
		margin-right: 20px;
		padding: 5px;
		border: 1px solid #ccc;
		margin-bottom: 20px;
		cursor: pointer;
	}
	
	.cert-info {
		align-content: start;
		display: flex;
		flex-direction: column;
		height: 100%;
		margin-left: 20px;
		justify-content: left;
		text-align: left;
		width: 200px;
		line-height: 50px;
	}
	
	.cert-update-time {
		font-size: 12px;
		color: #666;
	}
	
	.certModel:hover {
		border: 1px solid #0055ff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
	}
	
	.cert-name {
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		font-weight: bold;
	}
	
	.cert-btns {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	
	.cert-btns-btn {
		width: 60px;
		margin-top: 10px;
		margin-left: 0px!important;
		
	}
	
	.cert-mask-box {
		position: relative;
	}
	
	.cert-mask {
		background-color: rgba(0, 0, 0, 0.4);
		color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-weight: bold;
		text-align: center;
		line-height: 100px;
	}
	
	.underline {
		
	}
	
</style>
